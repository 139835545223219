import React, { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Pagination, SinglePagnination } from "../../Component";
import {
  fetchGetReviewByRestaurant,
  removeReviewData,
} from "../../Redux/Review/action";
import { useEffect } from "react";

const ToggleReview = ({ id }) => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  const review = useSelector((state) => state.review.review, shallowEqual);

  const totalData = useSelector(
    (state) => state.review.totalData,
    shallowEqual
  );
  const totalPage = useSelector(
    (state) => state.review.totalPage,
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchGetReviewByRestaurant(page, id));
    return () => {
      dispatch(removeReviewData());
    };
  }, []);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <div className="w-3/4">
          <h6 className="text-lg font-semibold">Our Reviews</h6>
          <hr className="w-14 border-2 border-primary mt-2" />
        </div>
      </div>

      <div className="w-full md:w-11/12 lg:w-3/4 mt-7">
        {review && review.length ? (
          review.map((val) => {
            return (
              <div className="mt-5 py-2" key={val.id}>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-x-5">
                    <div className="w-16 h-16 overflow-hidden rounded-full">
                      <img
                        src={`https://restaurant-dev.s3-ap-south-1.amazonaws.com/${val.restaurantimage}`}
                        width={2000}
                        alt="img"
                        height={0}
                        className="w-full h-full object-fill"
                      />{" "}
                    </div>
                    <div className=" flex flex-col">
                      <h6 className="font-medium">{val.fullName}</h6>
                      <div className="mt-1 flex items-center gap-x-4">
                        <p className="text-sm text-secondary">1 Review</p>
                        <p className="text-sm text-secondary">
                          {moment.utc(val.addedDate).local().fromNow()}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      val.rating < 2
                        ? "bg-red-700"
                        : val.rating < 4
                        ? "bg-orange-700"
                        : " bg-green-700"
                    } text-sm py-1 px-2 text-white rounded-md w-fit flex items-center gap-x-1`}
                  >
                    <AiFillStar className="fill-white" />
                    <p>{val.rating}</p>
                  </div>
                </div>

                {/* decription */}
                <p className="text-sm px-3 text-secondary mt-5 py-2">
                  {val.reviewText}
                </p>

                <hr className="mt-5 w-full" />
              </div>
            );
          })
        ) : (
          <div className="w-full flex justify-center items-center mt-20 h-full flex-col ">
            <AiOutlineStar className="text-7xl text-gray-300" />
            <h6 className="font-semibold text-xl mt-8 py-2">No Reviews Yet</h6>
            <p className="text-secondary mt-1">
              All the review for this restaurant will be shown here.
            </p>
          </div>
        )}
      </div>

      <div className="w-full mt-10">
        {totalPage > 5 ? (
          <Pagination
            totalData={totalData}
            totalPage={totalPage}
            page={page}
            setPage={setPage}
          />
        ) : (
          <SinglePagnination
            setPage={setPage}
            page={page}
            totalData={totalData}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  );
};

export default ToggleReview;
