import React, { useEffect, useState } from "react";
import { BsHeart } from "react-icons/bs";
import { RiShareCircleFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  Breadcrum,
  Button,
  CampaignCard,
  Download,
  Load,
  Shares,
  TitleContainer,
} from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetACtiveCampaignUser,
  fetchGetActiveCampaign,
  fetchGetCampaignDetailPage,
  fetchGetCampaignDetailPageUser,
  removeCampaignData,
} from "../../Redux/Campaign/action";
import Cookies from "js-cookie";
import { AiFillHeart } from "react-icons/ai";
import {
  fetchAddCampaignWishlist,
  fetchDeleteCampaignWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";

const CampaignDetailPage = () => {
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = Cookies.get("access_token");

  const { id } = useParams();

  const loading = useSelector(
    (state) => state.campaign.isloading,
    shallowEqual
  );

  const campaign = useSelector(
    (state) => state.campaign.campaign,
    shallowEqual
  );

  const detailpage = useSelector(
    (state) => state.campaign.detailPage,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  const handleClickAdd = (e, id) => {
    e.stopPropagation();
    if (!token) {
      navigate(`/auth?unauthorize=true&from=${window.location.pathname}`);
    } else {
      dispatch(fetchAddCampaignWishlist(id, token));
    }
  };

  const handleClickRemove = (e, id) => {
    e.stopPropagation();
    dispatch(fetchDeleteCampaignWishlist(id, token));
  };

  useEffect(() => {
    scrollToTop();
    token
      ? dispatch(fetchGetACtiveCampaignUser(1, token))
      : dispatch(fetchGetActiveCampaign(1));
    token
      ? dispatch(fetchGetCampaignDetailPageUser(id, token))
      : dispatch(fetchGetCampaignDetailPage(id));
    return () => {
      dispatch(removeCampaignData());
    };
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg && dispatch(fetchGetCampaignDetailPageUser(id, token));
      wishlistMsg && dispatch(fetchGetACtiveCampaignUser(1, token));
    }, 100);
  }, [wishlistMsg]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="py-16 md:py-24 relative">
      {showShare ? (
        <div
          className="w-full h-full absolute top-0 z-30 bg-black opacity-10"
          onClick={() => {
            setShowShare(false);
          }}
        ></div>
      ) : null}

      {showShare && <Shares setShowShare={setShowShare} />}

      <Breadcrum
        location="Kathmandu"
        title="Campaign"
        link="/campaign"
        title2={detailpage.campaignName}
      />
      <div className="mt-10 w-11/12 xl:w-3/4 mx-auto">
        <div className="grid w-full grid-cols-12 gap-x-4 h-full">
          <div className="col-span-12 md:col-span-5 w-full h-44 lg:h-60 overflow-hidden rounded-xl">
            <img
              src={detailpage.campaignImage}
              alt="advertisement"
              width={2000}
              height={0}
              className="object-fill w-full h-full"
            />
          </div>
          <div className="mt-10 md:mt-0 col-start-1 md:col-start-7 col-end-13  w-full h-full">
            <div className="flex items-center gap-x-5">
              <h4 className="text-xl md:text-2xl font-semibold">
                {detailpage.campaignName}
              </h4>
            </div>
            <h4 className="text-xl md:text-2xl font-semibold mt-2">
              Rs. {detailpage.price}
            </h4>
            <p className="text-sm text-secondary leading-7 mt-5">
              {detailpage.description}
            </p>
            <div className="flex items-center gap-x-5 mt-5">
              {detailpage.isWishlisted ? (
                <div
                  className="flex border-red-600 items-center gap-x-2 md:gap-x-4 cursor-pointer border rounded-xl w-fit px-5 py-3 mt-5 md:mt-0"
                  onClick={(e) => handleClickRemove(e, detailpage.id)}
                >
                  <AiFillHeart className="fill-red-600 text-xl" />
                  <p className="text-base text-red-600">
                    Remove from wishlist{" "}
                  </p>
                </div>
              ) : (
                <div
                  className="flex items-center gap-x-2 md:gap-x-4 cursor-pointer border rounded-xl w-fit px-5 py-3 mt-5 md:mt-0"
                  onClick={(e) => handleClickAdd(e, detailpage.id)}
                >
                  <BsHeart className="text-primary" />
                  <p className="text-base">Add to Wishlist </p>
                </div>
              )}
              <div
                className="flex items-center gap-x-2 cursor-pointer border rounded-xl w-fit px-5 py-3"
                onClick={() => setShowShare(true)}
              >
                <RiShareCircleFill className="text-primary text-lg" />
                <p>Share </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="w-full mt-10" />
        {/* menu items */}
        <h4 className="text-textPrimary text-xl font-semibold mt-8">
          Menu Items
        </h4>
        <hr className="w-16 border-2 border-primary mt-2" />
        <div className="w-full grid md:grid-cols-2 gap-x-5">
          {detailpage?.itemData?.map((val) => {
            return (
              <div key={val.id} className="mt-10 flex items-center gap-x-5">
                <div className="w-[4.5rem] md:w-20 h-16 lg:h-20 overflow-hidden rounded-full">
                  <img
                    src={val.itemImage}
                    alt="img"
                    width={2000}
                    height={0}
                    className="w-full h-full object-contain"
                  />
                </div>
                <div>
                  <h6 className="font-semibold">{val.itemName}</h6>
                  <p className="text-secondary">{val.description}</p>
                  <p className="text-sm font-medium">
                    Qty:{" "}
                    <span className="text-base text-primary">
                      {val.quantity}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
        <hr className="w-full mt-8" />
        <div className="mt-8 flex flex-col md:flex-row md:items-center justify-between">
          <p>
            Total Amount:{" "}
            <span className="text-xl font-semibold">
              Rs. {detailpage.price}
            </span>
          </p>
          <Button
            value="Place an Order"
            handleClick={() => {
              if (!token) {
                navigate(
                  `/auth?unauthorize=true&from=${window.location.pathname}`
                );
              } else {
                navigate(`/campaign-checkout/${id}`);
              }
            }}
            className="bg-primary text-white rounded-xl py-3 px-7 w-fit mt-5 md:mt-0"
          />
        </div>
      </div>
      <hr className="w-full mt-8" />
      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <TitleContainer
          title="You may also like"
          handleClick={() => navigate("/campaign")}
        />

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-5">
          {campaign &&
            campaign.slice(0, 4).map((data) => {
              return (
                <div className="mt-7" key={data.id}>
                  {" "}
                  <CampaignCard data={data} />{" "}
                </div>
              );
            })}
        </div>
      </div>
      {/* download */}
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <Download />
      </div>
    </div>
  );
};

export default CampaignDetailPage;
