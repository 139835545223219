import React from "react";
import { Khalti, cashonDelivery, stripe } from "../../../Asset/Asset";
import moment from "moment";
import { Button } from "../../../Component";
import { scrollToTop } from "../../../Component/NavigateTop";

const Review = ({
  details,
  city,
  payment,
  code,
  district,
  country,
  isSchedule,
  scheduleDate,
  setShowLocation,
  setShowSchedule,
  setIsSchedule,
}) => {
  return (
    <div className="w-full h-fit">
      {/* contact person detail */}
      <div className="w-full h-fit bg-white rounded-xl p-5">
        <div className="w-full flex items-center justify-between">
          <h6 className="font-semibold text-xl">Contact Person Details</h6>
        </div>

        <div className="mt-5">
          <h6 className="font-semibold text-lg">{details.deliverTo}</h6>
          <p className="font-medium py-1">{details.email}</p>
          <p className="font-medium py-1">{details.contactNumber}</p>
          {details.alternativeNumber ? (
            <p className="font-medium py-1">
              {details.alternativeNumber}{" "}
              <span className="text-sm text-secondary">(Alternative)</span>
            </p>
          ) : null}
        </div>
      </div>

      {/* delivery detail */}
      <div className="w-full h-fit bg-white rounded-xl p-5 mt-7">
        <div className="w-full flex items-center justify-between">
          <h6 className="font-semibold text-xl">Delivery Details</h6>
        </div>

        <div className="mt-5">
          <div className="flex items-center gap-x-5 flex-wrap">
            <p className="text-secondary text-sm mt-2">Delivery Location:</p>

            <p className="font-medium mt-2">
              {code && code !== "undefined" ? code + "," : ""} {city},{" "}
              {`${city === district ? "" : district}`}, {country}
            </p>
            <Button
              value="Change"
              handleClick={() => {
                scrollToTop();
                setShowLocation(true);
              }}
              className="bg-primary text-xs text-white rounded-xl px-5 w-fit py-2 mt-2"
            />
          </div>

          <div className="flex items-center gap-x-5 mt-5 flex-wrap">
            <p className="text-secondary text-sm mt-2">Delivery Date:</p>
            <p className="font-medium mt-2">
              {isSchedule
                ? moment.utc(scheduleDate).local().format("DD-MM-YYYY, h:mm A")
                : moment().format("MMM Do YYYY")}
            </p>
            <Button
              value="Change"
              handleClick={() => {
                scrollToTop();
                setShowSchedule(true);
                setIsSchedule(true);
              }}
              className="bg-primary text-xs text-white rounded-xl px-5 w-fit py-2 mt-2"
            />
          </div>
        </div>
      </div>

      {/* payment details */}
      <div className="w-full h-fit bg-white rounded-xl p-5 mt-7">
        <div className="w-full flex items-center justify-between">
          <h6 className="font-semibold text-xl">Payment Details</h6>
        </div>

        <div className="mt-5">
          <div className="flex items-center gap-x-5">
            <p className="text-secondary text-sm">Payment Method:</p>
            <p className="font-medium">
              {payment === "STRIPE" ? "Credit Card" : payment}
            </p>
          </div>
          <div className="mt-3">
            {payment === "KHALTI" ? (
              <img src={Khalti} alt="packages" className="w-16 h-9" />
            ) : payment === "STRIPE" ? (
              <img src={stripe} alt="packages" className="w-12 h-9" />
            ) : (
              <img src={cashonDelivery} alt="packages" className="w-8 h-8" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
