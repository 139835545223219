import { Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, FormikControl } from "../../../../Component";
import { Logo } from "../../../../Asset/Asset";
import { scrollToTop } from "../../../../Component/NavigateTop";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchCreateAccount,
  fetchVerifyReferralCode,
  removeAuthData,
} from "../../../../Redux/Auth/action";
import Cookies from "js-cookie";
import { IoMdArrowDropdown } from "react-icons/io";
import moment from "moment/moment";

const CreateProfile = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [gender, setGender] = useState("Male");
  const [success, setSuccess] = useState(false);
  const [referralCode, setReferralCode] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const message = useSelector((state) => state.auth.profileMsg, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);
  const referral = useSelector((state) => state.auth.referralMsg, shallowEqual);

  const userId = localStorage.getItem("GurugUid");
  const mobileNo = localStorage.getItem("GurugUMobile");
  const location = localStorage.getItem("GCity");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const token = Cookies.get("token");

  const verifyReferralCode = () => {
    dispatch(fetchVerifyReferralCode(referralCode, token));
    scrollToTop();
  };

  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    phone: mobileNo ? mobileNo : "",
    email: "",
    password: "",
    confirmPassword: "",
    dateOfBirth: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required*"),
    lastName: Yup.string().required("Required*"),
    email: Yup.string().email(),
    password: Yup.string()
      .min(8, "Password is too short")
      .required("Required*"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });

  const onSubmit = (values, onSubmitProps) => {
    const body = {
      userId: userId,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      phone: mobileNo ? mobileNo : "",
      email: values.email,
      password: values.password,
      confirmPassword: values.confirmPassword,
      dateOfBirth: moment(values.dateOfBirth).format("DD-MM-YYYY"),
      gender: gender,
      referralCode: referralCode,
      location: location,
      longitude: lat,
      latitude: lng,
      isAllowedLocationAccess: true,
    };
    dispatch(fetchCreateAccount(body, token));

    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setSuccess(true);
    message && Cookies.remove("token");
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setSuccess(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    success && navigate("/auth");
    success && Cookies.remove("token");
  }, [success]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="py-12 md:py-28 bg-blue-50 w-full h-full">
      <div className="w-11/12 md:w-3/4 xl:w-1/2 py-8 px-5 md:px-10 mx-auto h-full rounded-xl mt-14 bg-white ">
        <div className="flex justify-center items-center flex-col">
          <img src={Logo} width="200" height="200" alt="logo" />
          <h5 className="font-semibold text-3xl mt-10">Create Profile</h5>
          <p className="text-secondary py-2 mt-2">
            Create a profile for your account
          </p>
        </div>
        <div className="mt-16">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <div className="w-full flex flex-wrap md:flex-nowrap items-center gap-x-5">
                  <div className="w-full">
                    <FormikControl
                      label="First Name"
                      control="input"
                      name="firstName"
                      placeholder="Your First Name"
                    />
                  </div>

                  <div className="w-full mt-7 md:mt-0">
                    <FormikControl
                      label="Middle Name"
                      control="input"
                      name="middleName"
                      placeholder="Your Middle Name"
                    />
                  </div>
                </div>

                <div className="w-full md:w-1/2 mt-7">
                  <FormikControl
                    label="Last Name"
                    control="input"
                    name="lastName"
                    placeholder="Your Last Name"
                  />
                </div>

                <div className="w-full flex flex-wrap md:flex-nowrap items-center gap-x-5 mt-7">
                  <div className="w-full text-sm">
                    <label className="pb-2 font-medium flex text-sm items-center justify-between">
                      <div className="flex items-center gap-x-3">Gender</div>
                    </label>

                    <div
                      className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300 cursor-pointer relative"
                      onClick={() => setShowGender(!showGender)}
                    >
                      <div className="flex items-center justify-between ">
                        <p className={`${gender ? "" : "text-secondary"}`}>
                          {gender ? gender : "Select gender"}{" "}
                        </p>
                        <IoMdArrowDropdown />
                      </div>

                      {showGender && (
                        <div className="flex flex-col gap-y-2 absolute border top-12 rounded-lg w-full left-0 bg-white">
                          <p
                            className="hover:bg-gray-100 px-3 py-1.5"
                            onClick={() => setGender("Male")}
                          >
                            Male
                          </p>
                          <p
                            className="hover:bg-gray-100 px-3 py-1.5"
                            onClick={() => setGender("Female")}
                          >
                            Female
                          </p>
                          <p
                            className="hover:bg-gray-100 px-3 py-1.5"
                            onClick={() => setGender("Other")}
                          >
                            Other
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full mt-7 md:mt-0">
                    <FormikControl
                      label="Date of Birth"
                      control="input"
                      name="dateOfBirth"
                      type="date"
                    />
                  </div>
                </div>

                <div className="w-full mt-7">
                  <FormikControl
                    label="Phone"
                    control="input"
                    name="phone"
                    placeholder="Your phone number"
                    readOnly
                  />
                </div>

                <div className="w-full mt-7">
                  <FormikControl
                    label="Email"
                    optional="Optional"
                    control="input"
                    name="email"
                    placeholder="Your email address"
                  />
                </div>

                <div className="w-full flex flex-wrap md:flex-nowrap items-center gap-x-5 mt-7">
                  <div className="w-full">
                    <FormikControl
                      label="Password"
                      control="password"
                      name="password"
                      placeholder="Your Password"
                      type={`${showPassword ? "text" : "password"}`}
                      setShowPassword={setShowPassword}
                      showPassword={showPassword}
                    />
                  </div>
                  <div className="w-full mt-7 md:mt-0">
                    <FormikControl
                      label="Confirm Password"
                      control="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type={`${showConfirmPassword ? "text" : "password"}`}
                      setShowPassword={setShowConfirmPassword}
                      showPassword={showConfirmPassword}
                    />
                  </div>
                </div>
                <div className="w-full mt-7">
                  <label className="pb-2 font-medium flex text-textPrimary text-sm items-center justify-between">
                    <div className="flex items-center gap-x-3">
                      Referral
                      <p className="text-xs text-secondary">(If Any)</p>
                    </div>
                  </label>

                  <div className="flex flex-wrap md:flex-nowrap items-center gap-x-5">
                    <input
                      type="text"
                      readOnly={referral ? true : false}
                      className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
                      placeholder="Enter your referral code"
                      onChange={(e) => setReferralCode(e.target.value)}
                    />

                    <button
                      type="button"
                      onClick={verifyReferralCode}
                      className={`bg-primary text-white py-4 rounded-xl w-48 px-5 mt-5 md:mt-0 text-sm ${
                        referral ? "hidden" : "block"
                      }`}
                    >
                      Redeem Code
                    </button>
                  </div>
                </div>

                <p className="text-secondary text-sm mt-10 text-center">
                  By proceeding, you agree to our
                </p>
                <div className="flex items-center justify-center gap-x-3 text-sm mt-5">
                  <Link to="/terms-&-condition">
                    <p className="text-primary hover:underline cursor-pointer hover:font-medium">
                      Terms of Service
                    </p>
                  </Link>
                  <div className="border-r-2 h-4 text-textSecondary"></div>
                  <Link to="/privacy-policy">
                    <p className="text-primary hover:underline cursor-pointer hover:font-medium">
                      Privacy Policies
                    </p>
                  </Link>
                </div>
                <Button
                  type="submit"
                  value="Create Account"
                  className="w-full py-3 text-white bg-primary rounded-xl mt-10 text-base"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateProfile;
