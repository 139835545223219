import React, { useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AiOutlineShoppingCart } from "react-icons/ai";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../Asset/Asset";
import { RxHamburgerMenu } from "react-icons/rx";
import MobileMenu from "./MobileMenu";
import Cookies from "js-cookie";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetProfile } from "../../Redux/Profile/action";
import Avatar from "react-avatar";
import {
  MdArrowDropDown,
  MdLocationOn,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import Mycart from "../Cart/Mycart";
import Notification from "../Notification";
import { fetchGetAllNotification } from "../../Redux/Notification/action";

const Header = ({ setShowLocation }) => {
  const [headerShadow, setHeaderShadow] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [location, setLocation] = useState(localStorage.getItem("GCity"));
  const [street, setStreet] = useState(localStorage.getItem("GStreet"));

  const token = Cookies.get("access_token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const notificationCount = useSelector(
    (state) => state.notification.count,
    shallowEqual
  );
  const myCart = useSelector((state) => state.cart.myCart, shallowEqual);

  const handleClickLogout = () => {
    navigate("/");
    Cookies.remove("access_token");
    window.location.reload(false);
  };

  const handleClickNotification = () => {
    if (!token) {
      navigate("/auth");
    } else {
      setShowNotification(true);
      setShowCart(false);
    }
  };

  const handleClickCart = () => {
    if (!token) {
      navigate("/auth");
    } else {
      setShowCart(true);
      setShowNotification(false);
    }
  };

  useEffect(() => {
    const applyShadow = () => {
      if (window.scrollY >= 30) {
        setHeaderShadow(true);
      } else {
        setHeaderShadow(false);
      }
    };

    window.addEventListener("scroll", applyShadow);
  }, [headerShadow]);

  useEffect(() => {
    token && dispatch(fetchGetProfile(token));
    token && dispatch(fetchGetAllNotification(1, token));
  }, [token]);

  useEffect(() => {
    setTimeout(() => {
      setStreet(localStorage.getItem("GStreet"));
      setLocation(localStorage.getItem("GCity"));
    }, 100);
  });

  return (
    <div
      className={`h-16 sm:h-20 md:h-24 border border-b fixed z-50 bg-white w-full ${
        headerShadow ? "shadow-sm" : ""
      }`}
    >
      <div className="w-11/12 h-full mx-auto flex items-center justify-between relative">
        {showProfile && (
          <div className="w-1/4 border py-6 px-5 rounded-xl bg-white z-50 shadow-md absolute top-16 2xl:top-20 right-0">
            <h6 className="text-textPrimary font-bold text-lg">
              {profile && profile.firstName} {profile && profile.lastName}
            </h6>
            <p className="text-textSecondary text-sm mt-2">
              {profile && profile.email}
            </p>
            <hr className="mt-5" />
            <Link to="/me">
              <p
                className="text-primary mt-4 text-lg cursor-pointer hover:underline"
                onClick={() => setShowProfile(false)}
              >
                My Account
              </p>
            </Link>
            <p
              className="text-primary mt-3 text-lg cursor-pointer hover:underline"
              onClick={handleClickLogout}
            >
              Logout
            </p>
          </div>
        )}

        <img
          src={Logo}
          alt="logo"
          className="cursor-pointer w-32 h-32 md:w-44 md:h-44"
          onClick={() => navigate("/")}
        />

        {/* location section */}
        {window.location.pathname === "/" ? null : (
          <div
            className="w-36 md:w-60 border py-2 md:py-3 rounded-xl px-1.5 md:px-3 flex items-center justify-between cursor-pointer"
            onClick={() => setShowLocation(true)}
          >
            <div className="flex items-center gap-x-1 md:gap-x-2 w-10/12">
              <MdLocationOn className="text-sm md:text-xl text-primary" />
              <p className="text-[0.6rem] md:text-sm truncate text-gray-400 w-full">
                {street ? street : location ? location : "Location"}
              </p>
            </div>
            <MdArrowDropDown className="text-base md:text-xl text-gray-500" />
          </div>
        )}

        {/* right side */}
        <div className="md:flex items-center justify-end gap-x-8  hidden">
          <div className="flex items-center gap-x-5">
            <div
              className="w-fit p-2 rounded-full hover:bg-gray-100 cursor-pointer relative"
              onClick={handleClickNotification}
            >
              <IoMdNotificationsOutline className="text-2xl" />
              {token ? (
                <p className="absolute w-fit top-0 right-0 py-0.5 px-1.5 h-5 border text-white rounded-full text-[0.6rem] bg-primary">
                  {notificationCount}
                </p>
              ) : null}
            </div>
            <div
              className="w-fit p-2 rounded-full hover:bg-gray-100 cursor-pointer relative"
              onClick={handleClickCart}
            >
              <AiOutlineShoppingCart className="text-2xl" />
              {token ? (
                <p className="absolute w-fit top-0 right-0 py-0.5 px-1.5 h-5 border text-white rounded-full text-[0.6rem] bg-primary">
                  {myCart?.length}
                </p>
              ) : null}
            </div>
          </div>

          {token ? (
            <div
              className="flex items-center gap-x-2 cursor-pointer"
              onClick={() => {
                setShowProfile(!showProfile);
                setShowNotification(false);
                setShowCart(false);
              }}
            >
              {profile && profile.imageUrl ? (
                <div className="w-12 h-12">
                  <img
                    src={profile.imageUrl}
                    alt=""
                    className="w-full h-full rounded-full"
                  />
                </div>
              ) : (
                <Avatar
                  name={profile && profile.fullName}
                  size={40}
                  round={true}
                />
              )}
              <MdOutlineKeyboardArrowDown className="text-lg" />
            </div>
          ) : (
            <div className="flex items-center gap-x-3">
              <Button
                value="Sign in"
                handleClick={() => navigate("/auth")}
                className="border rounded-xl text-primary py-3 px-5"
              />
              <Button
                value="Sign up"
                handleClick={() => navigate("/auth/register")}
                className="border bg-primary text-white rounded-xl py-3 px-5"
              />
            </div>
          )}
        </div>

        {/* mobile menu */}
        <RxHamburgerMenu
          className="text-2xl block md:hidden cursor-pointer"
          onClick={() => setMobileMenu(true)}
        />

        <MobileMenu
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
          setShowCart={setShowCart}
          setShowNotification={setShowNotification}
        />

        <Mycart showCart={showCart} setShowCart={setShowCart} />
        <Notification
          showNotification={showNotification}
          setShowNotification={setShowNotification}
        />
      </div>
      {mobileMenu || showCart || showNotification ? (
        <div
          className="w-full absolute h-screen bg-black opacity-10"
          onClick={() => {
            setMobileMenu(false);
            setShowCart(false);
            setShowNotification(false);
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default Header;
