import React from "react";
import { GrClose } from "react-icons/gr";
import { shallowEqual, useSelector } from "react-redux";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

const Share = ({ setShowShare }) => {
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  return (
    <div className="w-11/12 md:w-1/2 2xl:w-1/3 h-fit top-20 left-[4%] md:left-1/4 2xl:left-1/3 md:top-32 border bg-white rounded-xl absolute z-40">
      <div className="flex justify-end py-2 px-5">
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => setShowShare(false)}
        >
          <GrClose className="text-xl md:text-2xl" />
        </div>
      </div>

      <h6 className="text-Primary font-medium text-lg px-8 ">
        Share this link via
      </h6>

      {/* Share icon  */}
      <div className="flex flex-col px-8 pt-4 pb-8 gap-y-5 mt-4 gap-x-4">
        <FacebookShareButton
          className="flex flex-col justify-center px-3 py-1 rounded-full bg-white border "
          url={profile.referralLink}
          quote={profile.referralMessage}
          hashtag="gurug"
        >
          <div
            className="flex items-center gap-x-5 font-medium"
            style={{ color: "#00639e" }}
          >
            <FacebookIcon className="rounded-full w-10 h-10" />
            <h6>Facebook</h6>
          </div>
        </FacebookShareButton>
        <TwitterShareButton
          className="px-3 py-1 rounded-full bg-white border"
          url={profile.referralLink}
        >
          <div
            className="flex items-center gap-x-5 font-medium"
            style={{ color: "#00639e" }}
          >
            <TwitterIcon className="rounded-full w-10 h-10" />
            <h6>Twitter</h6>
          </div>
        </TwitterShareButton>

        <WhatsappShareButton url={profile.referralLink}>
          <div
            className="flex items-center gap-x-5 font-medium"
            style={{ color: "#00639e" }}
          >
            <WhatsappIcon className="rounded-full w-10 h-10" />
            <h6>WhatsApp</h6>
          </div>
        </WhatsappShareButton>
        <EmailShareButton
          url={profile.referralLink}
          subject={profile.referralMessage}
          body={profile.referralTopic}
        >
          <div
            className="flex items-center gap-x-5 font-medium"
            style={{ color: "#00639e" }}
          >
            <EmailIcon className="rounded-full w-10 h-10" />
            <h6>Email</h6>
          </div>
        </EmailShareButton>
      </div>
    </div>
  );
};

export default Share;
