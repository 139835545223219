import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { TbArrowsSort } from "react-icons/tb";
import { setting } from "../../Asset/Asset";

const Header = ({
  toggle,
  handleClickAllOrders,
  handleClickCurrentOrders,
  handleClickPastOrders,
  handleClickCancelled,
  handleClickRejected,
}) => {
  return (
    <div className="w-full">
      <h6 className="font-semibold text-xl">Order History</h6>
      <div className="mt-8 md:mt-10 w-full flex items-center gap-x-2 md:gap-x-8">
        <h6
          onClick={handleClickCurrentOrders}
          className={`${
            toggle.current
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Current
        </h6>
        <h6
          onClick={handleClickPastOrders}
          className={`${
            toggle.past
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Past
        </h6>
        <h6
          onClick={handleClickCancelled}
          className={`${
            toggle.cancel
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Cancelled
        </h6>

        <h6
          onClick={handleClickRejected}
          className={`${
            toggle.rejected
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          Rejected
        </h6>
        <h6
          onClick={handleClickAllOrders}
          className={`${
            toggle.all
              ? "text-primary font-medium border-b-2 border-b-primary"
              : "text-secondary"
          } py-4 px-3 md:px-5 cursor-pointer text-[0.6rem] sm:text-sm md:text-base`}
        >
          All
        </h6>
      </div>
      <hr className="w-full mt-7" />

      {/* <div className="mt-10 w-full flex items-center justify-between">
        <div className="flex items-center gap-x-2 py-3 px-5 border rounded-md cursor-pointer">
          <img src={setting} width={16} height={0} alt="setting" />
          <p className="text-sm text-secondary">Filter Order History</p>
        </div>

        <div className=" w-fit h-full flex items-center gap-x-2 ">
          <div className="flex items-center justify-end gap-x-2">
            <TbArrowsSort />
            <p className="text-sm font-medium">Sort by:</p>
          </div>
          <div className="flex items-center gap-x-1 justify-end cursor-pointer">
            <h6 className="capitalize text-primary font-medium text-sm">
              Recent
            </h6>
            <IoIosArrowDown className="text-secondary text-xs" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Header;
