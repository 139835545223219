import React from "react";
import Header from "./Header";
import DeliveryDetails from "./DeliveryDetails";
import OrderSummary from "./OrderSummary";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchCartCheckout,
  fetchCartStripePay,
  fetchConfirmCart,
  fetchGetRestaurantCart,
  removeCheckoutData,
} from "../../Redux/Checkout/action";
import { Load } from "../../Component";
import Payment from "./Payment";
import Review from "./Review";
import { toast } from "react-toastify";
import ScheduleTime from "./Popup/ScheduleTime";
import { scrollToTop } from "../../Component/NavigateTop";
import moment from "moment";
import { removeCouponData } from "../../Redux/Coupon/action";
import { fetchGetMyCart, removeCartData } from "../../Redux/Cart/action";
import Khaltipopup from "./Popup/KhaltiPopup";
import { khaltiKey } from "../../Shared/BaseUrl";
import LocationPopup from "../Home/LocationPopup";
import SearchLocation from "../Home/SearchLocation";

const Checkout = () => {
  const [showLocation, setShowLocation] = useState(false);
  const [searchLocation, setSearchLocation] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [toggle, setToggle] = useState({
    delivery: true,
    payment: false,
    review: false,
  });

  const profile = useSelector((state) => state.profile.profile, shallowEqual);

  const [showSchedule, setShowSchedule] = useState(false);

  const [payment, setPayment] = useState("KHALTI");

  const [lat, setLat] = useState(localStorage.getItem("GLat"));
  const [lng, setLng] = useState(localStorage.getItem("GLng"));
  const [city, setCity] = useState(localStorage.getItem("GCity"));
  const [code, setCode] = useState(localStorage.getItem("GCode"));
  const [district, setDistrict] = useState(localStorage.getItem("GDistrict"));
  const [country, setCountry] = useState(localStorage.getItem("GCountry"));
  const [street, setStreet] = useState(localStorage.getItem("GStreet"));
  const [isSchedule, setIsSchedule] = useState(false);
  const [details, setDetails] = useState({
    deliverTo: profile.fullName ? profile.fullName : "",
    contactNumber: profile.phoneNumber ? profile.phoneNumber : "",
    alternativeNumber: "",
  });

  const [scheduleDate, setScheduleDate] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeApplied, setCouponCodeApplied] = useState(false);
  const [coinApplied, setCoinApplied] = useState(false);
  const [showKhaltiPopup, setShowKhaltiPopup] = useState(false);

  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate("");
  const token = Cookies.get("access_token");

  const sessionId = Cookies.get("GSession");

  let params = new URLSearchParams(document.location.search);
  let cartId = params.get("indx");
  let success = params.get("success");

  const loading = useSelector(
    (state) => state.checkout.isloading,
    shallowEqual
  );

  const orders = useSelector((state) => state.checkout.orders, shallowEqual);
  const message = useSelector((state) => state.checkout.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.checkout.errMsg,
    shallowEqual
  );

  const validateSuccess = useSelector(
    (state) => state.checkout.cMsg,
    shallowEqual
  );
  const validateFail = useSelector(
    (state) => state.checkout.cErrMsg,
    shallowEqual
  );

  const couponMsg = useSelector((state) => state.coupon.message, shallowEqual);
  const couponErrMsg = useSelector(
    (state) => state.coupon.errMsg,
    shallowEqual
  );
  const orderId = useSelector((state) => state.checkout.orderId, shallowEqual);

  const couponData = useSelector((state) => state.coupon.data, shallowEqual);
  const coinData = useSelector(
    (state) => state.checkout.coinData,
    shallowEqual
  );
  const coinMsg = useSelector((state) => state.checkout.coinMsg, shallowEqual);
  const coinErrMsg = useSelector(
    (state) => state.checkout.coinErrMsg,
    shallowEqual
  );

  const stripeRedirect = useSelector(
    (state) => state.checkout.stripe?.redirectURL,
    shallowEqual
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDetails({
      ...details,
      [name]: value,
    });
  };

  const handleClickContinueToPayment = () => {
    if (details.deliverTo && details.contactNumber) {
      const body = {
        deliveryAddress:
          code && code !== "undefined"
            ? code +
              ", " +
              city +
              ", " +
              (city === district ? "" : district) +
              ", " +
              country
            : "" +
              city +
              ", " +
              (city === district ? "" : district) +
              ", " +
              country,
        deliveryLatitude: lat,
        deliveryLongitude: lng,
        isScheduled: isSchedule,
        scheduledTime: scheduleDate.length
          ? scheduleDate
          : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
        deliveryContactNo: details.contactNumber,
        deliveryAlternateContactNo: details.alternativeNumber,
        deliveryFullName: details.deliverTo,
      };

      dispatch(fetchConfirmCart(body, cartId, token));

      localStorage.setItem("GName", details.deliverTo);
      localStorage.setItem("GContact", details.contactNumber);
      localStorage.setItem("GAlternate", details.alternativeNumber);
      localStorage.setItem("GSchedule", body.isScheduled);
      localStorage.setItem("GScheduledTime", body.scheduledTime);
    } else {
      toast.error("Please fill your details");
    }
  };

  const handleClickContinueToReview = () => {
    setToggle({
      delivery: true,
      payment: true,
      review: true,
    });
  };

  const handleClickPlaceOrder = () => {
    if (payment === "KHALTI") {
      scrollToTop();
      setShowKhaltiPopup(true);
    } else if (payment === "STRIPE") {
      const body = {
        cartId: cartId,
        restaurantId: id,
        amount: couponCodeApplied
          ? couponData?.grandTotal
          : coinApplied
          ? coinData?.grandTotal
          : orders.paymentObject.grandTotal,
      };
      dispatch(fetchCartStripePay(body, token));

      localStorage.setItem(
        "GSubTotal",
        couponCodeApplied
          ? couponData?.cartSubTotal
          : coinApplied
          ? coinData?.cartSubTotal
          : orders.paymentObject.cartSubTotal
      );

      localStorage.setItem(
        "GService",
        couponCodeApplied
          ? couponData?.serviceCharge
          : coinApplied
          ? coinData?.serviceCharge
          : orders.paymentObject.serviceCharge
      );
      localStorage.setItem("GTotal", body.amount);

      localStorage.setItem(
        "GCouponDiscount",
        couponCodeApplied ? couponData?.couponDiscount : 0
      );

      localStorage.setItem("GCouponApplied", couponCodeApplied);
      localStorage.setItem("GCoinApplied", coinApplied ? 100 : null);
      localStorage.setItem(
        "GCoinAmount",
        coinApplied ? coinData?.loyaltyDiscount : 0
      );

      localStorage.setItem(
        "GCouponCOde",
        couponCodeApplied ? couponCode : null
      );
    } else {
      const body = {
        cartId: orders.id,
        paymentMethod: payment,
        subTotal: orders.paymentObject.cartSubTotal,
        serviceCharge: orders.paymentObject.serviceCharge,
        grandTotal: orders.paymentObject.grandTotal,
        uniquePaymentId: "adkddald",
        deliveryInfo: {
          deliveryAddress:
            code && code !== "undefined"
              ? code +
                ", " +
                city +
                ", " +
                (city === district ? "" : district) +
                ", " +
                country
              : "" +
                city +
                ", " +
                (city === district ? "" : district) +
                ", " +
                country,
          deliveryLatitude: lat,
          deliveryLongitude: lng,
          isScheduled: isSchedule,
          scheduledTime: scheduleDate.length
            ? scheduleDate
            : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
          deliveryContactNo: details.contactNumber,
          deliveryAlternateContactNo: details.alternativeNumber,
          deliveryFullName: details.deliverTo,
        },
        isTakeAway: false,
      };

      dispatch(fetchCartCheckout(body, token));
    }
  };

  // khalti payment
  let khaltiConfig = {
    // replace this key with yours
    publicKey: khaltiKey,
    productIdentity: orders.restaurantid,
    productName: orders.restaurantName,
    productUrl: window.location.href,
    eventHandler: {
      onSuccess(payload) {
        setCheckoutLoading(true);
        // hit merchant api for initiating verfication
        const body = {
          cartId: orders.id,
          paymentMethod: payment,

          subTotal: couponCodeApplied
            ? couponData?.cartSubTotal
            : coinApplied
            ? coinData?.cartSubTotal
            : orders.paymentObject.cartSubTotal,

          serviceCharge: couponCodeApplied
            ? couponData?.serviceCharge
            : coinApplied
            ? coinData?.serviceCharge
            : orders.paymentObject.serviceCharge,

          grandTotal: couponCodeApplied
            ? couponData?.grandTotal
            : coinApplied
            ? coinData?.grandTotal
            : orders.paymentObject.grandTotal,

          uniquePaymentId: payload.idx,
          couponCode: couponCodeApplied ? couponCode : null,
          couponDiscountAmount: couponCodeApplied
            ? couponData?.couponDiscount
            : null,
          deliveryInfo: {
            deliveryAddress:
              code && code !== "undefined"
                ? code +
                  ", " +
                  city +
                  ", " +
                  (city === district ? "" : district) +
                  ", " +
                  country
                : "" +
                  city +
                  ", " +
                  (city === district ? "" : district) +
                  ", " +
                  country,
            deliveryLatitude: lat,
            deliveryLongitude: lng,
            isScheduled: isSchedule,
            scheduledTime: scheduleDate.length
              ? scheduleDate
              : moment.utc().format("YYYY-MM-DDTHH:mm:ss"),
            deliveryContactNo: details.contactNumber,
            deliveryAlternateContactNo: details.alternativeNumber,
            deliveryFullName: details.deliverTo,
          },
          isTakeAway: false,
          khaltiVerify: {
            token: payload.token,
            amount: payload.amount,
            cartId: orders.id,
            couponCode: couponCodeApplied ? couponCode : null,
            uniqueTransactionId: payload.idx,
          },
          couponCodeApplied: couponCodeApplied,
          coinUsed: coinApplied ? 100 : null,
          coinEquivalentAmount: coinApplied ? coinData?.loyaltyDiscount : null,
        };

        dispatch(fetchCartCheckout(body, token));
      },
      // onError handler is optional
      onError(error) {
        // handle errors
        toast.error(error);
      },
      onClose() {
        console.log("widget is closing");
      },
    },
  };

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetRestaurantCart(id, token));
    dispatch(fetchGetMyCart(token));
    dispatch(removeCouponData());
    setCouponCodeApplied(false);
    setCouponCode("");
    setCoinApplied(false);
    return () => {
      dispatch(removeCheckoutData());
      dispatch(removeCartData());
      dispatch(removeCouponData());
      setCouponCodeApplied(false);
      setCouponCode("");
      setCoinApplied(false);
    };
  }, [id]);

  useEffect(() => {
    setDetails({
      deliverTo: profile?.fullName,
      contactNumber: profile?.phoneNumber,
      alternativeNumber: "",
    });
  }, [profile]);

  useEffect(() => {
    validateSuccess &&
      setToggle({
        delivery: true,
        payment: true,
        review: false,
      });
  }, [validateSuccess]);

  useEffect(() => {
    validateFail && toast.error(validateFail);
    validateFail &&
      setToggle({
        delivery: true,
        payment: false,
        review: false,
      });
    setTimeout(() => {
      validateFail && dispatch(removeCheckoutData());
      validateFail && dispatch(fetchGetRestaurantCart(id, token));
    }, 100);
  }, [validateFail]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeCheckoutData());
    message && dispatch(removeCouponData());
    message && setCouponCodeApplied(false);
    message && setCouponCode("");
    message && setCoinApplied(false);
    message && Cookies.remove("GID");
    message && Cookies.remove("GSession");
    message && localStorage.removeItem("GName");
    message && localStorage.removeItem("GContact");
    message && localStorage.removeItem("GAlternate");
    message && localStorage.removeItem("GSchedule");
    message && localStorage.removeItem("GScheduledTime");
    message && localStorage.removeItem("GSubTotal");
    message && localStorage.removeItem("GService");
    message && localStorage.removeItem("GTotal");
    message && localStorage.removeItem("GCouponCOde");
    message && localStorage.removeItem("GCouponDiscount");
    message && localStorage.removeItem("GCoinAmount");
    message && localStorage.removeItem("GCoinApplied");
    message && localStorage.removeItem("GCouponApplied");
    setTimeout(() => {
      message && setCheckoutLoading(false);
      message && orderId && navigate(`/me/order-history/${orderId}`);
    }, 100);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeCheckoutData());
    errMessage && setCheckoutLoading(false);
    setTimeout(() => {
      errMessage && dispatch(fetchGetRestaurantCart(id, token));
    }, 100);
  }, [errMessage]);

  useEffect(() => {
    couponMsg && setCouponCodeApplied(true);
    couponMsg && toast.success(couponMsg);
  }, [couponMsg]);

  useEffect(() => {
    coinMsg && setCoinApplied(true);
    coinMsg && toast.success(coinMsg);
  }, [coinMsg]);

  useEffect(() => {
    couponErrMsg && setCouponCodeApplied(false);
    couponErrMsg && toast.error(couponErrMsg);

    setTimeout(() => {
      couponErrMsg && dispatch(removeCouponData());
    }, 100);
  }, [couponErrMsg]);

  useEffect(() => {
    coinErrMsg && setCoinApplied(false);
    coinErrMsg && toast.error(coinErrMsg);
    coinErrMsg && dispatch(removeCheckoutData());
    setTimeout(() => {
      coinErrMsg && dispatch(fetchGetRestaurantCart(id, token));
    }, 100);
  }, [coinErrMsg]);

  useEffect(() => {
    setTimeout(() => {
      setLat(localStorage.getItem("GLat"));
      setLng(localStorage.getItem("GLng"));
      setCity(localStorage.getItem("GCity"));
      setCode(localStorage.getItem("GCode"));
      setDistrict(localStorage.getItem("GDistrict"));
      setCountry(localStorage.getItem("GCountry"));
      setStreet(localStorage.getItem("GStreet"));
    }, 1000);
  });

  if (stripeRedirect) {
    window.location.href = stripeRedirect;
  }

  // stripe payment

  useEffect(() => {
    if (success !== null && sessionId && orders) {
      if (
        localStorage.getItem("GCouponCOde") &&
        localStorage.getItem("GCouponCOde") !== "null"
      ) {
        console.log("coupon code with");
        const body = {
          cartId: orders.id,
          paymentMethod: "STRIPE",

          subTotal: localStorage.getItem("GSubTotal"),

          serviceCharge: localStorage.getItem("GService"),

          grandTotal: localStorage.getItem("GTotal"),

          uniquePaymentId: sessionId,

          couponCode: localStorage.getItem("GCouponCOde"),

          couponDiscountAmount: localStorage.getItem("GCouponDiscount"),

          deliveryInfo: {
            deliveryAddress:
              code && code !== "undefined"
                ? code +
                  ", " +
                  city +
                  ", " +
                  (city === district ? "" : district) +
                  ", " +
                  country
                : "" +
                  city +
                  ", " +
                  (city === district ? "" : district) +
                  ", " +
                  country,
            deliveryLatitude: lat,
            deliveryLongitude: lng,
            isScheduled: localStorage.getItem("GSchedule"),
            scheduledTime: localStorage.getItem("GScheduledTime"),
            deliveryContactNo: localStorage.getItem("GContact"),
            deliveryAlternateContactNo: localStorage.getItem("GAlternate"),
            deliveryFullName: localStorage.getItem("GName"),
          },
          isTakeAway: false,

          couponCodeApplied: localStorage.getItem("GCouponApplied"),
          coinUsed: localStorage.getItem("GCoinApplied"),
          coinEquivalentAmount: localStorage.getItem("GCoinAmount"),
        };

        success &&
          cartId &&
          orders &&
          sessionId &&
          dispatch(fetchCartCheckout(body, token));
      } else {
        console.log("coupon code without");
        const body = {
          cartId: orders.id,
          paymentMethod: "STRIPE",

          subTotal: localStorage.getItem("GSubTotal"),

          serviceCharge: localStorage.getItem("GService"),

          grandTotal: localStorage.getItem("GTotal"),

          uniquePaymentId: sessionId,

          couponDiscountAmount: localStorage.getItem("GCouponDiscount"),

          deliveryInfo: {
            deliveryAddress:
              code && code !== "undefined"
                ? code +
                  ", " +
                  city +
                  ", " +
                  (city === district ? "" : district) +
                  ", " +
                  country
                : "" +
                  city +
                  ", " +
                  (city === district ? "" : district) +
                  ", " +
                  country,
            deliveryLatitude: lat,
            deliveryLongitude: lng,
            isScheduled: localStorage.getItem("GSchedule"),
            scheduledTime: localStorage.getItem("GScheduledTime"),
            deliveryContactNo: localStorage.getItem("GContact"),
            deliveryAlternateContactNo: localStorage.getItem("GAlternate"),
            deliveryFullName: localStorage.getItem("GName"),
          },
          isTakeAway: false,
          couponCodeApplied: localStorage.getItem("GCouponApplied"),
          coinUsed: localStorage.getItem("GCoinApplied"),
          coinEquivalentAmount: localStorage.getItem("GCoinAmount"),
        };

        success &&
          cartId &&
          orders &&
          sessionId &&
          dispatch(fetchCartCheckout(body, token));
      }
    }
  }, [success && cartId && orders && sessionId]);

  if (loading || checkoutLoading || !profile || success || !orders || !id) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="w-full h-full py-16 md:py-24 bg-gray-50 relative">
      {showSchedule && (
        <ScheduleTime
          setShowSchedule={setShowSchedule}
          setScheduleDate={setScheduleDate}
          scheduleDate={scheduleDate}
        />
      )}

      {showKhaltiPopup && (
        <Khaltipopup
          khaltiConfig={khaltiConfig}
          price={
            couponCodeApplied
              ? couponData?.grandTotal
              : coinApplied
              ? coinData?.grandTotal
              : orders.paymentObject.grandTotal
          }
          setShowKhaltiPopup={setShowKhaltiPopup}
        />
      )}

      {showLocation && (
        <LocationPopup
          setShowLocation={setShowLocation}
          setSearchLocation={setSearchLocation}
        />
      )}

      {searchLocation && (
        <SearchLocation setSearchLocation={setSearchLocation} />
      )}
      {showSchedule || showKhaltiPopup || searchLocation || showLocation ? (
        <div
          className="absolute w-full h-full top-0 bg-black opacity-10"
          onClick={() => {
            setShowSchedule(false);
            setIsSchedule(false);
            setScheduleDate("");
            setShowKhaltiPopup(false);
            setShowLocation(false);
            setSearchLocation(false);
          }}
        ></div>
      ) : null}

      <div className="w-11/12 xl:w-3/4 mx-auto">
        {/* header */}
        <div className="mt-12 md:mt-10">
          <Header toggle={toggle} />
        </div>

        <div className="w-full h-full grid md:grid-cols-2 gap-x-7 mt-10 md:mt-20">
          {toggle.delivery && !toggle.payment && !toggle.review ? (
            <DeliveryDetails
              lat={lat}
              lng={lng}
              setLat={setLat}
              setLng={setLng}
              city={city}
              setCity={setCity}
              code={code}
              setCode={setCode}
              setDistrict={setDistrict}
              district={district}
              setCountry={setCountry}
              country={country}
              street={street}
              setStreet={setStreet}
              isSchedule={isSchedule}
              setIsSchedule={setIsSchedule}
              handleInputChange={handleInputChange}
              details={details}
              setShowSchedule={setShowSchedule}
              scheduleDate={scheduleDate}
              setShowLocation={setShowLocation}
            />
          ) : toggle.delivery && toggle.payment && !toggle.review ? (
            <Payment payment={payment} setPayment={setPayment} />
          ) : (
            <Review
              details={details}
              city={city}
              code={code}
              district={district}
              country={country}
              payment={payment}
              isSchedule={isSchedule}
              scheduleDate={scheduleDate}
              setShowLocation={setShowLocation}
              setIsSchedule={setIsSchedule}
              setShowSchedule={setShowSchedule}
            />
          )}

          <OrderSummary
            toggle={toggle}
            handleClickContinueToPayment={handleClickContinueToPayment}
            handleClickContinueToReview={handleClickContinueToReview}
            payment={payment}
            handleClickPlaceOrder={handleClickPlaceOrder}
            setCouponCode={setCouponCode}
            couponCode={couponCode}
            cartId={cartId}
            couponCodeApplied={couponCodeApplied}
            coinApplied={coinApplied}
          />
        </div>
      </div>
    </div>
  );
};

export default Checkout;
