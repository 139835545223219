import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Button, Load } from "../../Component";
import { AiOutlineCheck } from "react-icons/ai";
import { IoMdInformationCircle } from "react-icons/io";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import ImagePopup from "./Popup/ImagePopup";
import PersonalDetailPopup from "./Popup/PersonalDetailPopup";
import { scrollToTop } from "../../Component/NavigateTop";
import SecurityPopup from "./Popup/SecurityPopup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchDeletProfileImg,
  removeAccountData,
} from "../../Redux/Account/action";
import Cookies from "js-cookie";
import { fetchGetProfile, removeProfileData } from "../../Redux/Profile/action";

const Profile = () => {
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [showPersonalDetailPopup, setShowPersonalDetailPopup] = useState(false);
  const [showSecurityPopup, setShowSecurityPopup] = useState(false);

  const token = Cookies.get("access_token");
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.profile.isloading, shallowEqual);
  const profile = useSelector((state) => state.profile.profile, shallowEqual);
  const message = useSelector((state) => state.account.message, shallowEqual);
  const errMsg = useSelector((state) => state.account.errMsg, shallowEqual);

  const handleRemoveProfile = () => {
    dispatch(fetchDeletProfileImg(profile.id, token));
  };

  useEffect(() => {
    message && toast.success(message);
    message && setShowImagePopup(false);
    message && setShowPersonalDetailPopup(false);
    message && setShowSecurityPopup(false);
    message && dispatch(removeProfileData());
    setTimeout(() => {
      message && dispatch(removeAccountData());
      message && dispatch(fetchGetProfile(token));
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);

    setTimeout(() => {
      errMsg && dispatch(removeAccountData());
    }, 1000);
  }, [errMsg]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Load />
      </div>
    );
  }

  return (
    <div className="w-full relative">
      <div className="w-full p-3">
        <h5 className="text-xl font-semibold">My Profile</h5>

        {/* photo section */}
        <div className="w-full mt-7 p-5 border rounded-xl">
          <h6 className="text-lg font-semibold">Your Photo</h6>
          <div className="mt-7 flex md:items-center gap-x-5 md:gap-x-6">
            <div className="hidden md:block">
              {profile.imageUrl ? (
                <div className="w-32 h-32 rounded-full overflow-hidden">
                  <img
                    src={profile.imageUrl}
                    alt="profile"
                    className="w-full h-full object-fill"
                  />
                </div>
              ) : (
                <Avatar name={profile.fullName} size={110} round={true} />
              )}
            </div>
            <div className="block md:hidden">
              {profile.imageUrl ? (
                <div className="w-24 h-24 rounded-full overflow-hidden">
                  <img
                    src={profile.imageUrl}
                    alt="profile"
                    className="w-full h-full object-fill"
                  />
                </div>
              ) : (
                <Avatar name={profile.fullName} size={80} round={true} />
              )}
            </div>
            <div className=" w-full">
              <p className="text-sm text-secondary">
                File smaller than 10MB and at least 400px by 400px
              </p>
              <div className="w-full flex-wrap md:flex-nowrap flex items-center gap-x-5 mt-5">
                <Button
                  value="Upload Photo"
                  handleClick={() => setShowImagePopup(true)}
                  className="w-full md:w-fit bg-primary text-white py-3 px-5 rounded-xl"
                />

                <Button
                  value="Remove"
                  handleClick={handleRemoveProfile}
                  className="w-full md:w-fit text-red-700 py-3 px-5 rounded-xl border mt-5 md:mt-0"
                />
              </div>
            </div>
          </div>
        </div>

        {/* personal detail */}
        <div className="w-full mt-7 p-5 border rounded-xl">
          <div className="w-full flex items-center justify-between">
            <h6 className="text-lg font-semibold">Personal Details</h6>
            <div
              onClick={() => {
                setShowPersonalDetailPopup(true);
                scrollToTop();
              }}
              className="py-3 rounded-xl border borer-xl cursor-pointer px-5 transition ease-in-out duration-300 hover:bg-gray-50 flex items-center gap-x-2"
            >
              <Button value="Edit" />
              <PiPencilSimpleLineBold className="text-lg" />
            </div>
          </div>
          {/* name */}
          <div className="w-full flex items-center justify-between mt-5">
            <div className="flex flex-col gap-y-2 w-3/4">
              <p className="text-xs md:text-sm text-secondary">Name</p>
              <p className="text-sm md:text-base w-3/4 md:w-11/12">
                {profile.firstName} {profile.middleName} {profile.lastName}
              </p>
            </div>
          </div>

          {/* phone */}
          <div className="w-full flex items-center justify-between mt-7">
            <div className="flex flex-col gap-y-2 w-full md:w-3/4">
              <p className="text-xs md:text-sm text-secondary">Phone Number</p>
              <div className="flex gap-x-5 items-center w-full md:w-11/12">
                <p className="text-sm md:text-base">{profile.phoneNumber}</p>
                {profile.phoneNumber ? (
                  profile.mobile_verified ? (
                    <div className="flex rounded-full items-center text-xs md:text-sm gap-x-2 py-1.5 px-3 md:px-4 bg-green-100 text-green-700">
                      <AiOutlineCheck className="text-base md:text-lg" />
                      <p>verified</p>
                    </div>
                  ) : (
                    <div className="flex rounded-full text-xs md:text-sm items-center gap-x-2 py-1.5 px-3 md:px-4 bg-red-100 text-red-700">
                      <IoMdInformationCircle className="text-base md:text-lg" />
                      <p>Not Verified</p>
                    </div>
                  )
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>

          {/* email */}
          <div className="w-full flex items-center justify-between mt-7">
            <div className="flex flex-col gap-y-2 w-full md:w-3/4 lg:w-10/12 2xl:w-3/4">
              <p className="text-xs md:text-sm text-secondary">Email Address</p>
              <div className="flex gap-x-5 items-center w-full md:w-11/12">
                <p className="text-sm md:text-base w-full truncate">
                  {profile.email ? profile.email : "-"}
                </p>
              </div>
            </div>
          </div>

          {/* gender */}
          <div className="w-full flex items-center justify-between mt-7">
            <div className="flex flex-col gap-y-2 w-full md:w-3/4">
              <p className="text-xs md:text-sm text-secondary">Gender</p>
              <p className="text-sm md:text-base w-full md:w-11/12">
                {profile.gender ? profile.gender : "-"}
              </p>
            </div>
          </div>

          {/* dob */}
          <div className="w-full flex items-center justify-between mt-7">
            <div className="flex flex-col gap-y-2 w-full md:w-3/4">
              <p className="text-xs md:text-sm text-secondary">Date of birth</p>
              <p className="text-sm md:text-base w-full md:w-11/12">
                {profile.dob ? profile.dob : "-"}
              </p>
            </div>
          </div>

          {/* address */}
          <div className="w-full flex items-center justify-between mt-7">
            <div className="flex flex-col gap-y-2 w-full md:w-3/4">
              <p className="text-xs md:text-sm text-secondary">Address</p>
              <p className="text-sm md:text-base w-full md:w-11/12">
                {profile.location}
              </p>
            </div>
          </div>
        </div>

        {/* Security */}
        {profile.isSocialLogin ? null : (
          <div className="w-full mt-7 p-5 border rounded-xl">
            <div className="w-full flex items-center justify-between">
              <h6 className="text-lg font-semibold">Security</h6>
              <div
                onClick={() => {
                  setShowSecurityPopup(true);
                  scrollToTop();
                }}
                className="py-3 rounded-xl border borer-xl cursor-pointer px-5 transition ease-in-out duration-300 hover:bg-gray-50 flex items-center gap-x-2"
              >
                <Button value="Edit" />
                <PiPencilSimpleLineBold className="text-lg" />
              </div>
            </div>
            <div className="flex flex-col gap-y-2 w-full mt-5">
              <p className="text-xs md:text-sm text-secondary">Password</p>
              <p className="text-sm md:text-base">XXXXXXXXXXXXXXXXX</p>
            </div>
          </div>
        )}
      </div>

      {/* popup modals */}
      {showImagePopup && <ImagePopup setImagePopupModal={setShowImagePopup} />}
      {showPersonalDetailPopup && (
        <PersonalDetailPopup
          profile={profile}
          setShowPersonalDetailPopup={setShowPersonalDetailPopup}
        />
      )}

      {showSecurityPopup && (
        <SecurityPopup setShowSecurityPopup={setShowSecurityPopup} />
      )}

      {showImagePopup || showPersonalDetailPopup || showSecurityPopup ? (
        <div
          className="w-full h-full absolute top-0 bg-black opacity-10"
          onClick={() => {
            setShowImagePopup(false);
            setShowPersonalDetailPopup(false);
            setShowSecurityPopup(false);
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default Profile;
