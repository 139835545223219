import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const reviewLoading = () => ({
  type: ActionTypes.REVIEW_LOADING,
});

export const removeReviewData = () => ({
  type: ActionTypes.REMOVE_REVIEW_DATA,
});

export const fetchAddReview = (body, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/v1/api/restaurant/review-rating/add-new`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_REVIEW_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_REVIEW_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchGetReviewByRestaurant = (page, id) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v1/api/restaurant/review-rating/user/get/${id}?page=${page}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_REVIEW_BY_RESTAURANT,
        payload: data,
      });
    });
};

export const fetchUpdateReview = (body, token) => async (dispatch) => {
  await axios
    .put(`${BaseUrl}/v1/api/restaurant/review-rating/update-review`, body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.UPDATE_REVIEW_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.UPDATE_REVIEW_FAIL,
        payload: e?.response?.data,
      });
    });
};

export const fetchGetReviewById = (id, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/v1/api/restaurant/review-ratingget-review-rating-by-id?reviewId=${id}`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_REVIEW_BY_ID,
        payload: data,
      });
    });
};
