import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { IoIosArrowForward, IoMdClose } from "react-icons/io";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchDeleteItemFromCart,
  fetchDeleteRestaurantFromCart,
  fetchGetMyCart,
  fetchUpdateCartItem,
  removeCartData,
} from "../../Redux/Cart/action";
import moment from "moment";
import { useState } from "react";
import { MdDeleteForever, MdOutlineAddCircle } from "react-icons/md";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";
import {
  fetchGetRestaurantDetailPage2,
  fetchGetRestaurantMenu,
} from "../../Redux/Restaurant/action";
import { fetchGetRestaurantCart } from "../../Redux/Checkout/action";

const Mycart = ({ showCart, setShowCart }) => {
  const [isOpen, setIsOpen] = useState({});
  const [resId, setResId] = useState("");
  const [cartId, setCartId] = useState("");
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  const token = Cookies.get("access_token");
  const navigate = useNavigate();

  const myCart = useSelector((state) => state.cart.myCart, shallowEqual);

  const message = useSelector((state) => state.cart.updateMsg, shallowEqual);
  const errMsg = useSelector((state) => state.cart.errMsg, shallowEqual);

  const detail = useSelector(
    (state) => state.restaurant.detaulPages,
    shallowEqual
  );

  const msg = useSelector((state) => state.cart.msg, shallowEqual);
  const errMessage = useSelector(
    (state) => state.cart.errMessage,
    shallowEqual
  );

  const handleDeleteRestaurant = (id, resId) => {
    dispatch(fetchDeleteRestaurantFromCart(id, token));

    setTimeout(() => {
      dispatch(fetchGetRestaurantMenu(resId));
      token && dispatch(fetchGetRestaurantCart(resId, token));
    }, 1000);
  };

  const handleDeleteItems = (id, resId) => {
    dispatch(fetchDeleteItemFromCart(id, token));

    setTimeout(() => {
      dispatch(fetchGetRestaurantMenu(resId));
      token && dispatch(fetchGetRestaurantCart(resId, token));
    }, 1000);
  };

  const handleIncreaseQuantity = (id, quantity) => {
    const body = {
      updatedItems: [
        {
          cartItemId: id,
          quantity: quantity + 1,
        },
      ],
    };

    dispatch(fetchUpdateCartItem(body, token));
  };

  const handleDecreaseQuantity = (id, quantity) => {
    const body = {
      updatedItems: [
        {
          cartItemId: id,
          quantity: quantity > 1 ? quantity - 1 : quantity,
        },
      ],
    };

    dispatch(fetchUpdateCartItem(body, token));
  };

  const handleOpen = (item) => {
    setIsOpen((prevState) => ({
      [item]: !prevState[item],
    }));

    if (show === item) {
      return setShow(null);
    }
    setShow(item);
  };

  const handleClickCheckout = (resId, cartId) => {
    setResId(resId);
    setCartId(cartId);
    dispatch(fetchGetRestaurantDetailPage2(resId));
  };

  useEffect(() => {
    showCart &&
      detail.isOpen === true &&
      navigate(`/checkout/${resId}?indx=${cartId}`);

    showCart && detail.isOpen === true && setShowCart(false);
  }, [detail]);

  useEffect(() => {
    showCart && detail.isOpen === false && toast.error("Restaurant is closed");

    // isOpen && detail.isOpen === false && setShowCart(true);
  }, [detail]);

  useEffect(() => {
    token && dispatch(fetchGetMyCart(token));
    return () => {
      dispatch(removeCartData());
    };
  }, [token]);

  useEffect(() => {
    message && dispatch(removeCartData());
    setTimeout(() => {
      message && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    errMsg && dispatch(removeCartData());
    setTimeout(() => {
      errMsg && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [errMsg]);

  useEffect(() => {
    msg && showCart && toast.success(msg);
    msg && showCart && dispatch(removeCartData());
    setTimeout(() => {
      msg && showCart && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [msg]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeCartData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetMyCart(token));
    }, 100);
  }, [errMessage]);

  return (
    <div
      className={` bg-white fixed right-0 top-0 pb-10 h-full w-full md:w-3/4 lg:w-1/2 xl:w-2/5 2xl:w-2/6 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        showCart ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <div className="flex w-full justify-between mt-5 px-5">
        <h5 className="text-lg font-semibold">
          My Cart <span className="text-primary">({myCart.length})</span>
        </h5>

        <div
          className="p-2 w-fitflex justify-end hover:bg-gray-200 cursor-pointer rounded-md"
          onClick={() => {
            setShowCart(false);
            setShow(false);
            setIsOpen({});
          }}
        >
          <IoMdClose className="text-2xl" />
        </div>
      </div>

      <hr className="mt-5" />

      {myCart.map((val) => {
        return (
          <div
            key={val.id}
            className={`mt-3  hover:bg-gray-100 pt-2 pb-5 ${
              show === val.id ? "bg-gray-100" : ""
            }`}
          >
            <div
              className="flex items-center gap-x-5 px-5 cursor-pointer"
              onClick={() => {
                handleOpen(val.id);
              }}
            >
              <div className="w-16 h-12 md:w-20 md:h-16 overflow-hidden rounded-full">
                <img
                  src={val.restaurantImageUrl}
                  alt="img"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-full">
                <div className="flex justify-between w-full">
                  <div className="flex gap-x-2 md:gap-x-5 w-full">
                    <h5 className="font-semibold w-2/3 md:w-3/4 text-sm md:text-base">
                      {val.restaurantName}
                    </h5>
                    <p className="bg-blue-100 text-primary py-1 px-3 rounded-xl text-[0.65rem] md:text-xs font-medium w-fit h-fit">
                      {val?.cartItemDto?.length} Items
                    </p>
                  </div>
                  <IoIosArrowForward
                    className={`arrow transition ease-in-out duration-500 delay-100 text-sm md:text-base ${
                      !isOpen[val.id] ? "close" : " open"
                    }`}
                  />
                </div>
                <p className="text-xs md:text-sm text-secondary py-1">
                  Total Nrs{" "}
                  <span className="text-sm md:text-base text-black font-semibold">
                    {val.paymentObject.grandTotal}
                  </span>
                </p>

                <p className="text-xs md:text-sm text-secondary py-1">
                  {moment
                    .utc(val.updatedDate ? val.updatedDate : val.addedDate)
                    .local()
                    .fromNow()}
                </p>
              </div>
            </div>

            {/* sub items */}
            <div
              className={`${
                show === val.id
                  ? "w-full h-full ease-in-out transition-all duration-1000 "
                  : "hidden"
              }`}
            >
              <div className="flex items-center justify-between px-5 border mt-3">
                <div
                  className="w-full text-sm flex items-center gap-x-2 justify-center py-3 cursor-pointer hover:bg-white"
                  onClick={() => {
                    navigate(`/restaurant/${val.restaurantid}`);
                    setShowCart(false);
                  }}
                >
                  <MdOutlineAddCircle className="text-primary text-xl" />
                  <p>Add more items</p>
                </div>

                <div className="border-l-2 border-gray-200 h-10"></div>

                <div
                  className="w-full text-sm flex items-center gap-x-2 py-3 justify-center cursor-pointer hover:bg-white"
                  onClick={() =>
                    handleDeleteRestaurant(val.id, val.restaurantid)
                  }
                >
                  <MdDeleteForever className="text-red-600 text-xl" />
                  <p>Clear cart</p>
                </div>
              </div>

              {/* items */}

              {val?.cartItemDto?.map((itm) => {
                return (
                  <div
                    className="w-full mt-3 p-5 cursor-pointer hover:bg-white"
                    key={itm.id}
                  >
                    <div className="w-full flex gap-x-5">
                      {/* image and title */}
                      <div className="w-12 md:w-16 h-10 md:h-14 overflow-hidden rounded-full">
                        <img
                          src={itm.imageUrl}
                          alt="img"
                          className="w-full h-full object-cover"
                        />
                      </div>

                      <div className="w-full">
                        <div className="w-full items-center justify-between flex">
                          <h6 className="font-medium text-sm">
                            {itm.itemName}
                          </h6>
                          <div
                            className="w-fit p-2 rounded-full hover:bg-gray-100 cursor-pointer"
                            onClick={() =>
                              handleDeleteItems(itm.id, val.restaurantid)
                            }
                          >
                            <MdDeleteForever className="text-2xl text-red-600" />
                          </div>
                        </div>
                        {/* price and quntity */}
                        <div className="w-full flex items-center justify-between mt-2">
                          <h6 className="font-semibold">
                            Rs. {itm.price} x {itm.quantity}
                          </h6>
                          <div className="flex items-center gap-x-3">
                            <div
                              className="px-2 font-medium text-base border border-primary rounded-md cursor-pointer text-primary"
                              onClick={() =>
                                handleDecreaseQuantity(itm.id, itm.quantity)
                              }
                            >
                              -
                            </div>
                            <p className="text-base font-semibold">
                              {itm.quantity}
                            </p>
                            <div
                              className="px-2 font-medium text-base border border-primary rounded-md cursor-pointer text-primary"
                              onClick={() =>
                                handleIncreaseQuantity(itm.id, itm.quantity)
                              }
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* special instruction */}
                    {itm.note ? (
                      <div className="mt-2 text-sm py-2">
                        <p className="font-medium">Special Instruction: </p>
                        <p className="text-secondary py-1">{itm.note}</p>
                      </div>
                    ) : null}
                  </div>
                );
              })}

              <hr className="w-full" />
              {/* charges */}
              <div className="w-full p-2 md:p-5 bg-white">
                <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
                  <p>Subtotal</p>
                  <p>Rs. {val.paymentObject?.cartSubTotal}</p>
                </div>

                <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
                  <div className="flex items-center gap-x-3 w-3/4">
                    <p className="w-1/2 md:w-1/3 lg:w-2/4">Delivery Charge</p>
                    <p className="text-[0.6rem] lg:text-xs text-red-700">
                      (May vary based on the delivery location)
                    </p>
                  </div>
                  <p>Rs. {val.paymentObject?.totalDeliveryCharge}</p>
                </div>

                <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
                  <p>Service Charge</p>
                  <p>Rs. {val.paymentObject?.serviceCharge}</p>
                </div>

                <div className="w-full flex items-center justify-between py-1 text-xs md:text-sm font-medium">
                  <p>Tax</p>
                  <p>Rs. {val.paymentObject?.taxAmount}</p>
                </div>
              </div>
              {/* grand total */}
              <div className="w-full py-2 flex items-center justify-between font-semibold text-sm md:text-lg px-2 md:px-5 bg-white">
                <p>Grand Total</p>
                <p>Rs. {val.paymentObject?.grandTotal}</p>
              </div>

              {/* proceed to checkout */}
              <div className="w-full px-5 mt-3">
                <Button
                  handleClick={() =>
                    handleClickCheckout(val.restaurantid, val.id)
                  }
                  value="Proceed to Checkout"
                  className="w-full bg-primary text-white py-2 md:py-3 text-center rounded-md text-sm md:text-base"
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Mycart;
