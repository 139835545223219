import React from "react";
import { IoMdClose } from "react-icons/io";
import { usePlacesWidget } from "react-google-autocomplete";
import { useState } from "react";
import { useEffect } from "react";
import {
  fetchGetActivePreorder,
  fetchGetActivePreorderUser,
} from "../../Redux/Preorder/action";
import {
  fetchGetNearbyRestaurant,
  fetchGetNearbyRestaurantUser,
  fetchGetPopularRestaurant,
  fetchGetPopularRestaurantUser,
} from "../../Redux/Restaurant/action";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import {
  fetchGetACtiveCampaignUser,
  fetchGetActiveCampaign,
} from "../../Redux/Campaign/action";

const SearchLocation = ({ setSearchLocation }) => {
  const [latitude, setLatitude] = useState(localStorage.getItem("GLat"));
  const [longitude, setLongitude] = useState(localStorage.getItem("GLng"));
  const [city, setCity] = useState(localStorage.getItem("GCity"));
  const [district, setDistrict] = useState(localStorage.getItem("GDistrict"));
  const [code, setCode] = useState(localStorage.getItem("GCode"));
  const [country, setCountry] = useState(localStorage.getItem("GCountry"));

  const keyword = "";
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU",

    onPlaceSelected: (place) => {
      const addressComponents = place.address_components;

      localStorage.setItem("GStreet", place.formatted_address);

      // Find the city in the address components (it might have different types)
      const cityComponent = addressComponents.find(
        (component) =>
          component.types.includes("sublocality_level_1") ||
          component.types.includes("sublocality") ||
          component.types.includes("political")
      );

      const codeComponent = addressComponents.find((component) =>
        component.types.includes("plus_code")
      );

      const districtComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_2")
      );

      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );

      setLatitude(place?.geometry?.location?.lat());
      setLongitude(place?.geometry?.location?.lng());
      setCode(codeComponent?.long_name);
      setDistrict(districtComponent?.long_name);
      setCountry(countryComponent?.long_name);

      setCity(
        place.formatted_address
          ? place.formatted_address
          : cityComponent?.long_name
      );

      setTimeout(() => {
        setSearchLocation(false);
      }, 1000);
    },

    options: {
      types: ["address"],
      componentRestrictions: {
        country: "np",
      },
    },
  });

  useEffect(() => {
    localStorage.setItem("GLat", latitude);
    localStorage.setItem("GLng", longitude);
    localStorage.setItem("GCity", city);
    localStorage.setItem("GCode", code);
    localStorage.setItem("GDistrict", district);
    localStorage.setItem("GCountry", country);
  }, [longitude, latitude, city, code, district, country]);

  const handleCallAPi = () => {
    token
      ? dispatch(fetchGetActivePreorderUser(1, latitude, longitude, token))
      : dispatch(fetchGetActivePreorder(1, latitude, longitude));
    token
      ? dispatch(
          fetchGetPopularRestaurantUser(
            1,
            city,
            latitude,
            longitude,
            keyword,
            token
          )
        )
      : dispatch(
          fetchGetPopularRestaurant(1, city, latitude, longitude, keyword)
        );
    token
      ? dispatch(
          fetchGetNearbyRestaurantUser(
            1,
            city,
            latitude,
            longitude,
            keyword,
            token
          )
        )
      : dispatch(
          fetchGetNearbyRestaurant(1, city, latitude, longitude, keyword)
        );

    token
      ? dispatch(fetchGetACtiveCampaignUser(1, token))
      : dispatch(fetchGetActiveCampaign(1));
  };

  useEffect(() => {
    handleCallAPi();
  }, [latitude, longitude, city]);

  return (
    <div className="w-11/12 md:w-3/5 lg:w-5/12 xl:w-1/3 2xl:w-[30%] mx-auto h-fit border overflow-y-auto bg-white shadow-md z-40 pb-5 left-4 md:left-[20%] lg:left-1/4 xl:left-1/3 rounded-xl absolute top-28 md:top-40">
      <div className="sticky top-0 pt-4 bg-white">
        <div className="flex justify-between items-center w-full px-5">
          <h6 className="text-xl font-semibold">Select Your Location</h6>
          <div
            className="p-2 hover:bg-gray-200 cursor-pointer rounded-md"
            onClick={() => setSearchLocation(false)}
          >
            <IoMdClose className="text-xl" />
          </div>
        </div>

        <hr className="w-full mt-5" />
      </div>

      <div className="px-5 mt-5">
        <div className="text-sm">
          <label className="pb-2 font-medium flex text-sm items-center justify-between">
            <div className="flex items-center gap-x-3">Search Location</div>{" "}
          </label>
          <input
            ref={ref}
            className="border border-gray-300 w-full p-3 text-sm rounded-lg outline-gray-300"
            placeholder="Search Property location"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchLocation;
