import React, { useEffect, useState } from "react";
import { noRestaurant } from "../../Asset/Asset";
import { useNavigate } from "react-router-dom";
import {
  Advertisement,
  Breadcrum,
  Pagination,
  RestaurantCard,
  Search,
  SinglePagnination,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import {
  fetchGetNearbyRestaurant,
  fetchGetNearbyRestaurantUser,
} from "../../Redux/Restaurant/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { useMemo } from "react";
import debounce from "lodash.debounce";
import { fetchGetAdvertisement } from "../../Redux/Advertisement/action";
import { removeWishlistData } from "../../Redux/Wishlist/action";
import { toast } from "react-toastify";

const NearbyRestaurant = () => {
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = Cookies.get("access_token");

  const street = localStorage.getItem("GStreet");
  const location = localStorage.getItem("GCity");
  const lat = localStorage.getItem("GLat");
  const lng = localStorage.getItem("GLng");

  const nearby = useSelector((state) => state.restaurant.nearby, shallowEqual);

  const advertisements = useSelector(
    (state) => state.advertisement.advertisement.promotions,
    shallowEqual
  );

  const totalPage = useSelector(
    (state) => state.restaurant.totalPage,
    shallowEqual
  );
  const totalData = useSelector(
    (state) => state.restaurant.totalData,
    shallowEqual
  );

  const wishlistMsg = useSelector(
    (state) => state.wishlist.message,
    shallowEqual
  );

  const handleNavigate = (id) => {
    navigate(`/nearby-restaurant/${id}`);
  };

  const onchangeSearchHandler = (e) => {
    setKeyword(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    token
      ? dispatch(
          fetchGetNearbyRestaurantUser(page, location, lat, lng, keyword, token)
        )
      : dispatch(fetchGetNearbyRestaurant(page, location, lat, lng, keyword));
  }, [page, keyword]);

  useEffect(() => {
    scrollToTop();
    dispatch(fetchGetAdvertisement());
  }, []);

  useEffect(() => {
    wishlistMsg && toast.success(wishlistMsg);
    wishlistMsg && dispatch(removeWishlistData());
    setTimeout(() => {
      wishlistMsg &&
        dispatch(
          fetchGetNearbyRestaurantUser(page, location, lat, lng, keyword, token)
        );
    }, 100);
  }, [wishlistMsg]);

  return (
    <div className="py-16 md:py-24">
      <Breadcrum
        location={street ? street : location}
        title="Nearby Restaurants"
      />

      <div className="w-11/12 xl:w-3/4 mx-auto mt-10">
        <Search
          placeholder="Search Nearby Restaurant"
          onchangeSearchHandler={debouncedResults}
        />
      </div>

      <hr className="w-full mt-5" />
      {nearby && nearby.length ? (
        <>
          <div className="w-11/12 xl:w-3/4 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
            {nearby &&
              nearby.slice(0, 6).map((data) => {
                return (
                  <div className="mt-10" key={data.id}>
                    <RestaurantCard
                      data={data}
                      handleNavigate={handleNavigate}
                    />
                  </div>
                );
              })}
          </div>

          {/* advertisement */}
          {nearby && nearby.length ? (
            advertisements && advertisements.length ? (
              <div
                className="w-full h-full px-5 mt-10"
                style={{ background: "#FAFAFB" }}
              >
                <Advertisement data={advertisements} />
              </div>
            ) : null
          ) : null}

          <div className="w-11/12 xl:w-3/4 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5">
            {nearby &&
              nearby.slice(6, 20).map((data) => {
                return (
                  <div className="mt-10" key={data.id}>
                    <RestaurantCard
                      data={data}
                      handleNavigate={handleNavigate}
                    />
                  </div>
                );
              })}
          </div>

          <div className="w-full mt-10">
            {totalPage > 5 ? (
              <Pagination
                totalData={totalData}
                totalPage={totalPage}
                page={page}
                setPage={setPage}
              />
            ) : (
              <SinglePagnination
                setPage={setPage}
                page={page}
                totalData={totalData}
                totalPage={totalPage}
              />
            )}
          </div>
        </>
      ) : (
        <div>
          <div className="w-full h-80">
            <img
              src={noRestaurant}
              alt="image"
              className="w-full h-full object-contain"
            />
          </div>
          <h6 className="text-center font-semibold text-xl md:text-3xl">
            No Restaurant Found
          </h6>
        </div>
      )}
    </div>
  );
};

export default NearbyRestaurant;
