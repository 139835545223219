import React, { useEffect, useRef, useState } from "react";
import { gurug } from "../../Asset/Asset";
import { shallowEqual, useSelector } from "react-redux";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckLg } from "react-icons/bs";
import { toast } from "react-toastify";

const ToggleMenu = ({ handleAddtoCart, setShowCart }) => {
  const [marginTop, setMarginTop] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const propertiesRef = useRef(null);

  const menu = useSelector((state) => state.restaurant.menu, shallowEqual);
  const orders = useSelector((state) => state.checkout.orders, shallowEqual);

  useEffect(() => {
    if (propertiesRef.current && selectedCategory !== null) {
      // Calculate the target position with a marginTop

      window.innerWidth < 767 ? setMarginTop(110) : setMarginTop(2);
      // const marginTop = 60; // Adjust this value based on your design
      const categoryProperties = propertiesRef.current.querySelector(
        `.category-${selectedCategory}`
      );

      if (categoryProperties) {
        const targetPosition = categoryProperties.offsetTop - marginTop;

        propertiesRef.current.scrollTo({
          top: targetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [selectedCategory]);

  const handleCategoryClick = (categoryIndex) => {
    setSelectedCategory(categoryIndex);
  };

  return (
    <div className="grid grid-cols-12 gap-x-8 h-full">
      {/* category section */}
      <div className="hidden md:block md:col-span-4 w-full  max-h-36rem overflow-y-auto">
        <div className="flex items-center gap-x-3 px-7 py-2 h-16 sticky top-0 bg-white">
          <img src={gurug} alt="logo" width={30} height={0} className="" />
          <p className="font-medium text-sm lg:text-base">Our categories</p>
        </div>

        <div className="mt-5">
          {menu.map((val, index) => {
            return (
              <div
                className={`flex flex-col cursor-pointer mt-2 hover:bg-gray-100 ${
                  selectedCategory === index ? "bg-sky-100" : ""
                }`}
                onClick={() => handleCategoryClick(index)}
                key={val.categoryId}
              >
                <h6
                  className={`font-medium py-3 text-xs lg:text-base px-5 ${
                    selectedCategory === index
                      ? " border-l-4 border-primary text-primary"
                      : ""
                  }`}
                >
                  {val.categoryName}
                </h6>
              </div>
            );
          })}
        </div>
      </div>

      {/* menu section */}
      <div
        className="col-start-1 md:col-start-5 col-end-13 w-full max-h-36rem overflow-x-hidden overflow-y-auto relative"
        ref={propertiesRef}
      >
        <div className={`sticky h-24 md:hidden bg-white w-[98%] top-0`}>
          {/* <Search placeholder="Search for Cuisine, dish here" /> */}

          {/* category */}
          <div className="mt-5 md:hidden flex gap-x-3 overflow-x-auto w-full">
            {menu.map((val, index) => {
              return (
                <div
                  className={`cursor-pointer mt-2 w-full hover:bg-gray-100 ${
                    selectedCategory === index ? "bg-sky-100" : ""
                  }`}
                  onClick={() => handleCategoryClick(index)}
                  key={val.categoryId}
                >
                  <h6
                    className={`font-medium py-3 w-full text-xs lg:text-base px-5 ${
                      selectedCategory === index
                        ? " border-l-4 border-primary text-primary"
                        : ""
                    }`}
                  >
                    {val.categoryName}
                  </h6>
                </div>
              );
            })}
          </div>
        </div>

        {/* menu */}
        <div className="2xl:px-5">
          {menu.map((val, index) => {
            return (
              <div
                className={`mt-2 py-2 category-${index}`}
                key={val.categoryId}
              >
                <h6 className="text-lg font-semibold">{val.categoryName}</h6>
                <hr className="w-14 border-2 border-primary mt-2" />
                {val.itemsList.map((itm) => {
                  return (
                    <div
                      className="mt-10 flex gap-x-5 justify-between"
                      key={itm.id}
                    >
                      <div className="w-14 h-14 lg:w-16 lg:h-16 2xl:w-20 2xl:h-20 overflow-hidden rounded-full">
                        {itm.imageUrl ? (
                          <img
                            src={itm.imageUrl}
                            alt="img"
                            height={0}
                            className="w-full h-full object-fill"
                          />
                        ) : (
                          <img
                            src={gurug}
                            alt="img"
                            height={0}
                            className="w-full h-full object-fill"
                          />
                        )}
                      </div>
                      <div className="w-3/4 md:w-10/12">
                        <div className="flex justify-between">
                          <h5 className="text-base lg:text-lg font-semibold w-full md:w-1/2 2xl:w-3/5 h-fit truncate">
                            {/* sdsadsadjshdjksjhhhh hjhksdas hkkhksd hkjhkjhd
                            hjkhkjhsd kjhasd */}
                            {itm.itemName}
                          </h5>
                          {orders?.cartItemDto?.find(
                            (items) => itm?.id === items?.itemId
                          ) ? (
                            <div
                              className="hidden  text-white bg-primary md:flex items-center py-1 md:py-2 w-fit px-3 md:px-5 rounded-xl cursor-pointer gap-x-1 md:gap-x-2 text-xs md:text-sm"
                              onClick={() => setShowCart(true)}
                            >
                              <p>View Cart</p>
                            </div>
                          ) : (
                            <div
                              className="hidden md:flex border items-center py-1 md:py-2 w-fit px-3 md:px-5 rounded-xl cursor-pointer gap-x-1 md:gap-x-2 text-xs md:text-sm"
                              onClick={() => handleAddtoCart(itm.id)}
                            >
                              <p className="text-primary text-base md:text-lg font-medium">
                                +
                              </p>
                              <p>Add to Cart</p>
                            </div>
                          )}
                        </div>
                        <p className="text-xs lg:text-sm text-secondary w-full mt-2 md:mt-0 md:w-1/2 2xl:w-3/5">
                          {itm.description}
                        </p>

                        <p className="font-semibold mt-2">Rs.{itm.price}</p>

                        {orders?.cartItemDto?.find(
                          (items) => itm?.id === items?.itemId
                        ) ? (
                          <div
                            className="flex text-primary border-primary md:hidden mt-5  border items-center py-1 md:py-2 w-fit px-3 md:px-5 rounded-xl cursor-pointer gap-x-1 md:gap-x-2 text-xs md:text-sm"
                            onClick={() =>
                              toast.success("Item already added to cart")
                            }
                          >
                            <p className="text-primary text-base md:text-lg font-medium">
                              <BsCheckLg />
                            </p>
                            <p>Added</p>
                          </div>
                        ) : (
                          <div
                            className="flex md:hidden mt-5  border items-center py-1 md:py-2 w-fit px-3 md:px-5 rounded-xl cursor-pointer gap-x-1 md:gap-x-2 text-xs md:text-sm"
                            onClick={() => handleAddtoCart(itm.id)}
                          >
                            <p className="text-primary text-base md:text-lg font-medium">
                              +
                            </p>
                            <p>Add to Cart</p>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                <hr className="w-full mt-5" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ToggleMenu;
