import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  myReviews: [],
  referral: [],
  totalData: "",
  totalPage: "",
  message: "",
  errMsg: "",
};

export const ACcount = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PROFILE_IMG_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Profile Image Uploaded Successfully",
      };

    case ActionTypes.UPDATE_PROFILE_IMG_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload?.message,
      };

    case ActionTypes.DELETE_PROFILE_IMG_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.DELETE_PROFILE_IMG_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.errMsg,
      };

    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_MY_REVIEWS:
      return {
        ...state,
        isloading: false,
        success: true,
        myReviews: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.GET_MY_REFERRAL_HISTORY:
      return {
        ...state,
        isloading: false,
        success: true,
        referral: action.payload.data,
        totalData: action.payload.totalData,
        totalPage: action.payload.totalPage,
      };

    case ActionTypes.REMOVE_ACCOUNT_DATA:
      return {
        isloading: true,
        success: false,
        myReviews: [],
        referral: [],
        totalData: "",
        totalPage: "",
        message: "",
        errMsg: "",
      };

    default:
      return state;
  }
};
